export default defineNuxtRouteMiddleware(async (to, from) => {

  const console = useConsole();
  const requestUrl = useRequestURL();

  console.log('🔌 MDLW:', 'catch-all', requestUrl.pathname);

  if(requestUrl.pathname.match(/^\/moto\/(wideo|galeria)\//)) {
    const articleSlug = requestUrl.pathname.replace(/^\/moto\/[^\/]+\//, '');
    const redirection = "/moto/artykul/" + articleSlug + requestUrl.search;
    return navigateTo(redirection,{redirectCode: 301});
  }


  return navigateTo("/" + (requestUrl.search || ""),{redirectCode: 301});

})
